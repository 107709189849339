import React, { useState } from "react";
import { makeStyles, IconButton, ThemeProvider, createTheme, Slider, Dialog } from "@material-ui/core";
import { ClearRounded, Description, StarRounded, ZoomInRounded, ZoomOutRounded, ZoomOutMapRounded, Close } from "@material-ui/icons";
import CustomSelect from "./CustomSelect.web";
import DOMPurify from "dompurify";
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";
import { ConnectorCardLink, DataItem } from "../../blocks/chatbot5/src/EndUserChatBotController"
import ReactPlayer from "react-player";
import Markdown from "react-markdown";


const youtubeImage = require("./youtube.png")

type ShareVideoCardProps = {
    message: string;
    videoURL: string;
    videoData: {
        title: string;
        description: string | null;
        thumbnail_url: string;
    };
    className?: string;
}

type SliderScaleCardProps = {
    message: string;
    min: number | null;
    max: number | null;
    onUserInputConfirm: (cardId: number, value: number | string | string[]) => void;
    cardId: number;
    reset: boolean;
}

type SingleChoiceCardProps = {
    message: string;
    options: string[];
    onUserInputConfirm: SliderScaleCardProps["onUserInputConfirm"];
    cardId: number;
    reset: boolean;
}

type ShareUserGuideProps = {
    message: string;
    userGuides: Array<{
        id: number;
        title: string;
        content: string;
    }>;
    width?: number | string;
    className?: string;
    headerClassName?: string;
}
interface OpenAIResponseCardProps {
    message: string;
    buttons?: DataItem[];
    onOptionClick?: (option: string) => void;
    onUserInputConfirmAi: (message: string) => void;
    cardId: number;
    reset: boolean;
}

const sliderScaleTheme = createTheme({
    overrides: {
        MuiSlider: {
            root: {
                color: "var(--header-background)",
                padding: 0,
            },
            rail: {
                backgroundColor: "var(--chat-name-text)",
                height: 4,
                borderRadius: 100,
            },
            track: {
                height: 4,
                backgroundColor: "var(--header-background)",
                borderRadius: 100,
            },
            thumb: {
                boxShadow: "none",
                width: 6,
                height: 9,
                marginTop: "-3px",
                borderRadius: "100%",
                marginLeft: 0,
                '&:hover': {
                    boxShadow: "none",
                },
                '&.Mui-focusVisible': {
                    boxShadow: "none",
                },
            },
            active: {
                boxShadow: "none",
            },
            valueLabel: {
                fontSize: 'calc(var(--font-size) * 1.6)',
                lineHeight: "16px",
                fontFamily: "var(--font-family,Inter) !important",
                backgroundColor: "#F1F5F9",
                boxShadow: "0px 2px 8px 0px #00000014",
                borderRadius: "8px",
                "& > span": {
                    backgroundColor: "transparent",
                    width: 43,
                    height: 32,
                    borderRadius: 8,
                    '& > span': {
                        color: "#475569",
                    },
                },
                "&::after": {
                    content: `""`,
                    position: "absolute",
                    top: "100%",
                    left: "50%",
                    marginLeft: "-8px",
                    borderWidth: "10px",
                    borderStyle: "solid",
                    borderColor: "#F1F5F9 transparent transparent transparent",
                },
            },
        },
    },
})

const shareUserGuideTheme = createTheme({
    overrides: {
        MuiBackdrop: {
            root: {
                backgroundColor: "#0F172A"
            },
        },
        MuiPaper: {
            root: {
                scrollbarWidth: "none",
                '&::-webkit-scrollbar': {
                    width: 0
                },
            },
            elevation24: {
                boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017"
            },
            rounded: {
                borderRadius: 24
            },
        },
        MuiDialog: {
            paperWidthSm: {
                width: 768,
                maxWidth: 768,
                '@media(max-width: 576px)': {
                    width: "95vw",
                    maxWidth: "95vw"
                },
            },
            paper: {
                '@media(max-width: 576px)': {
                    margin: 0
                },
            },
        },
    },
})

const useStyles = makeStyles({
    card: {
        border: "1px solid var(--card-border)",
        backgroundColor: "var(--card-background)",
        color: "var(--card-text)",
        padding: "12px 14px",
        display: "flex",
        gap: 10,
        flexDirection: "column",
        borderRadius: "12px",
        fontFamily: "var(--font-family,Cairo) !important",
        fontWeight: 400,
        fontSize: "calc(var(--font-size) * 1.4)",
        lineHeight: "24px",
        width: 283,
        '&.border-br-0': {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        '& > p': {
            whiteSpaceCollapse: "preserve",
            fontFamily: "var(--font-family,Cairo) !important"
        },
    },
    openAICard: {
        '& p, & h1, & h2, & h3, & h4, & h5, & h6, & span': {
            fontFamily: "var(--font-family, Cairo) !important",
            color: "var(--card-text)",
        },
        '& a': {
            fontFamily: "var(--font-family, Cairo) !important",
            fontWeight: 400,
            fontSize: "calc(var(--font-size) * 1.4)",
            lineHeight: "24px",
            color: "var(--card-selected-button-text)",
            textDecoration: "var(--text-decoration)"
        },
        '& img': {
            display: "block",
            maxWidth: "100%",
        },
        '& button': {
            fontWeight: 400,
            lineHeight: "24px",
            fontSize: "calc(var(--font-size) * 1.4)",
            fontFamily: "var(--font-family, Cairo) !important",
        },
    },
    optionContainer: {
        display: "flex",
        flexWrap: "wrap",
        gap: "10px 4px",
        listStyle: "none",
        paddingLeft: "0px !important",
    },
    option: {
        cursor: "pointer",
        border: "2px solid var(--card-button-border)",
        backgroundColor: "var(--card-button-background)",
        color: "var(--card-button-text)",
        padding: "8px 20px",
        borderRadius: 50,
        fontFamily: "var(--font-family, Cairo) !important",
        fontWeight: 400,
        fontSize: "calc(var(--font-size) * 1.4)",
        lineHeight: "14px",
        display: "flex",
        gap: 6,
        alignItems: "center",
        '&:hover, &[aria-selected="true"]': {
            borderColor: "var(--card-selected-button-border)",
            color: "var(--card-selected-button-text)",
            backgroundColor: "var(--card-selected-button-background)"
        },
        '& > button': {
            all: "unset",
            cursor: "pointer",
            color: "inherit",
        },
    },
    separator: {
        width: "100%",
        border: "1px solid var(--border-seperator)",
    },
    confirmBtn: {
        all: "unset",
        cursor: "pointer",
        width: "max-content",
        border: "2px solid var(--card-button-border)",
        backgroundColor: "var(--card-button-background)",
        color: "var(--card-button-text)",
        padding: "4px 20px",
        borderRadius: 50,
        fontFamily: "var(--font-family,Cairo) !important",
        fontWeight: 400,
        fontSize: "calc(var(--font-size) * 1.4)",
    },
    ratingContainer: {
        display: "flex",
        justifyContent: "center",
        gap: 13,
    },
    ratingIconBtn: {
        padding: 0,
        color: "var(--chat-name-text)",
        '&:hover': {
            backgroundColor: "transparent",
        },
        '&[data-hovered="true"]': {
            color: "var(--header-background)"
        },
    },
    linkContainer: {
        boxSizing: "border-box",
        width: 283,
        backgroundColor: "var(--body-background)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 12,
        border: "1px solid var(--card-background)",
        padding: 16,
        '& > button': {
            all: "unset",
            cursor: "pointer",
            display: "flex",
            gap: 13,
            alignItems: "center",
            color: "var(--card-selected-button-text)",
            textDecoration: "var(--text-decoration)",
            '& > span': {
                fontFamily: "var(--font-family,Roboto) !important",
                fontWeight: 400,
                fontSize: "calc(var(--font-size) * 1.4)",
                lineHeight: "14px",
            },
            '& > img': {
                borderRadius: 6,
            },
        },
        '&.border-tr-0': {
            borderTopWidth: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
        },
    },
    sliderScaleContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
    },
    sliderLabelContainer: {
        display: "flex",
        justifyContent: "space-between",
        gap: 6,
        '& > div': {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "0px 2px",
            color: "var(--card-selected-button-text)",
            '& > span': {
                fontFamily: "var(--font-family,Roboto) !important",
                fontWeight: 500,
                fontSize: "calc(var(--font-size) * 1.4)",
                lineHeight: "24px"
            },
        },
    },
    dropdownSelect: {
        padding: 8,
        backgroundColor: "var(--card-button-background)",
        border: "1px solid var(--card-button-border)",
        borderRadius: 12,
        gap: 8,
        '& > span': {
            fontFamily: "var(--font-family,Roboto) !important",
            fontWeight: 400,
            fontSize: "calc(var(--font-size) * 1.4)",
            lineHeight: "19px",
            color: "#697077",
        },
        '& > svg': {
            color: "#21272A",
        },
    },
    dropdownSelectOption: {
        padding: "8px",
        fontFamily: "var(--font-family,Roboto) !important",
        fontSize: "calc(var(--font-size) * 1.4)",
        lineHeight: "16px",
        color: "#64748B",
        '&:hover': {
            backgroundColor: "#F8FAFC",
        },
        '&:first-child': {
            borderRadius: "12px 12px 0 0",
        },
        '&:last-child': {
            borderRadius: "0 0 12px 12px",
        },
    },
    shareUserGuideHeader: {
        display: "flex",
        padding: "12px 14px",
        alignItems: "center",
        gap: 10,
        backgroundColor: "var(--card-background)",
        borderRadius: "12px 12px 0 0",
        border: "1px solid var(--card-border)",
        fontFamily: "var(--font-family,Cairo) !important",
        fontWeight: 600,
        fontSize: "calc(var(--font-size) * 1.4)",
        lineHeight: "24px",
        color: "var(--card-selected-button-text)",
        '& > svg': {
            color: "#475569"
        },
    },
    shareUserGuideControls: {
        borderRadius: "0px 0px 12px 12px",
        padding: "8px 14px",
        backgroundColor: "var(--card-background)",
        display: "flex",
        gap: 10,
        '& > button': {
            all: "unset",
            flex: 1,
            cursor: "pointer",
            textAlign: "center",
            color: "#334155",
        },
        '& .vertical-separator': {
            height: 24,
            border: "1px solid var(--chat-name-text)",
        },
    },
    shareUserGuideContent: {
        padding: "0px 4px 4px",
        backgroundColor: "var(--card-background)",
        '& .share-user-inner-container': {
            backgroundColor: "var(--card-button-background)",
            fontSize: "calc(var(--font-size) * 1.4)",
            lineHeight: "24px",
            color: "var(--card-text)",
            fontFamily: "var(--font-family,Cairo) !important",
            fontWeight: 400,
            padding: "8px 6px",
            maxHeight: 250,
            overflowX: "hidden",
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
                width: 0,
            },
            "& .react-transform-wrapper": {
                width: "100%",
            },
        },
    },
    shareUserGuideDialogHeader: {
        padding: "24px 40px",
        borderBottom: "1px solid #CBD5E1",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 10,
        position: "sticky",
        top: 0,
        zIndex: 2,
        backgroundColor: "#FFFFFF",
        '& > h4': {
            margin: 0,
            fontFamily: "Cairo",
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
            color: "#0F172A"
        },
        '& > button': {
            all: "unset",
            cursor: "pointer",
        },
    },
    shareUserGuideDialogContent: {
        padding: "24px 40px",
        fontFamily: "Cairo",
        fontSize: 16,
        lineHeight: "24px",
        color: "#0F172A"
    },
    videoContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 13,
        backgroundColor: "#F1F5F9",
        borderRadius: 8,
        cursor: "pointer",
        '& .react-player-container, & .react-player__preview': {
            borderRadius: "0px 0px 8px 8px",
        },
    },
    videoTitleContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 8,
        padding: 8,
        fontSize: "calc(var(--font-size) * 1.4)",
        lineHeight: "24px",
        fontWeight: 400,
        color: "#51ABB3",
        '& > div': {
            display: "flex",
            gap: 4,
            alignItems: "center",
            '& > span': {
                fontFamily: "var(--font-family,Roboto) !important"
            },
        },
        '& > p': {
            lineHeight: "16px",
            color: "#0F172A",
            fontFamily: "var(--font-family,Roboto) !important",
            '&:hover': {
                color: "#51ABB3"
            },
        },

    },
    playIconOuterContainer: {
        height: 40,
        width: 40,
        border: "3px solid #FEFEFE",
        borderRadius: 80,
    },
    playIconInnerContainer: {
        width: "100%",
        height: "100%",
        border: "3px solid #64748B",
        borderRadius: 80,
        display: "grid",
        placeItems: "center",
    },
    playIcon: {
        height: 20,
        width: 20,
        borderWidth: "10px 0 10px 15px",
        marginLeft: "7px",
        borderStyle: "solid",
        borderColor: "transparent transparent transparent #64748B",
        borderRadius: 3,
    },
})

const SingleChoiceCard = ({ message, options, onUserInputConfirm, cardId, reset }: SingleChoiceCardProps) => {
    const classes = useStyles()
    const [hide, setHide] = useState(false)

    const onOptionClick = (option: string) => () => {
        setHide(true)
        onUserInputConfirm(cardId, option)
    }

    return (
        <div className={classes.card} >
            <p>{message}</p>
            {
                (!hide || reset) ? (
                    <ul className={classes.optionContainer} >
                        {
                            options.map((option) => (
                                <li key={option} className={classes.option} onClick={onOptionClick(option)}  >{option}</li>
                            ))
                        }
                    </ul>
                ) : null
            }
        </div>
    )
}

const SendMessageCard = ({ message }: { message: string }) => {
    const classes = useStyles()
    return (
        <div className={classes.card}>
            <p>{message}</p>
        </div>
    )
}

const RatingCard = ({ cardId, message, onUserInputConfirm, reset }: Omit<SingleChoiceCardProps, "options">) => {
    const classes = useStyles()
    const [hoveredRatings, setHoveredRatings] = useState<number[]>([])
    const [hide, setHide] = useState(false)

    const onRatingHover = (ratingIndex: number) => () => {
        const newHoveredRatings = Array.from({ length: ratingIndex }, (_, index) => index + 1)
        setHoveredRatings(newHoveredRatings)
    }

    const onRatingLeave = (ratingIndex: number) => () => {
        const newHoveredRatings = hoveredRatings.filter(rating => rating !== ratingIndex)
        setHoveredRatings(newHoveredRatings)
    }

    const onRatingClick = (ratingIndex: number) => () => {
        onUserInputConfirm(cardId, ratingIndex)
        setHide(true)
        setHoveredRatings([])
    }

    const ratings = [1, 2, 3, 4, 5]

    return (
        <div className={classes.card}>
            <p>{message}</p>
            {
                (!hide || reset) ? (
                    <div className={classes.ratingContainer} onMouseLeave={() => setHoveredRatings([])} >
                        {
                            ratings.map((rating) => (
                                <IconButton
                                    key={rating}
                                    data-hovered={hoveredRatings.includes(rating)}
                                    onMouseEnter={onRatingHover(rating)}
                                    onMouseLeave={onRatingLeave(rating)}
                                    onClick={onRatingClick(rating)}
                                    className={classes.ratingIconBtn}
                                >
                                    <StarRounded htmlColor="currentColor" />
                                </IconButton>
                            ))
                        }
                    </div>
                ) : null
            }
        </div>
    )
}

const MultiChoiceCard = ({ message, options, cardId, onUserInputConfirm, reset }: SingleChoiceCardProps) => {
    const classes = useStyles()
    const [selectedOptionsSet, setSelectedOptionsSet] = useState<Set<string>>(new Set([]))
    const [hide, setHide] = useState(false)

    const handleSelectOption = (option: string) => () => {
        const newSelectOptionsSet = new Set([...selectedOptionsSet])
        newSelectOptionsSet.add(option)
        setSelectedOptionsSet(newSelectOptionsSet)
    }
    const handleRemoveOption = (option: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        const newSelectOptionsSet = new Set([...selectedOptionsSet])
        newSelectOptionsSet.delete(option)
        setSelectedOptionsSet(newSelectOptionsSet)
    }

    const onConfirmClick = () => {
        if(selectedOptionsSet.size === 0) {
            return;
        }
        setHide(true)
        onUserInputConfirm(cardId, [...selectedOptionsSet])
        setSelectedOptionsSet(new Set([]))
    }

    return (
        <div className={classes.card} >
            <p>{message}</p>
            {
                (!hide || reset) ? (
                    <>
                        <ul className={classes.optionContainer}>
                            {
                                options.map((option) => {
                                    return (
                                        <li
                                            key={option}
                                            onClick={handleSelectOption(option)}
                                            aria-selected={selectedOptionsSet.has(option)}
                                            className={classes.option}
                                            style={{ padding: "6px 8px" }}
                                        >
                                            {option}
                                            {selectedOptionsSet.has(option) && (
                                                <button onClick={handleRemoveOption(option)} >
                                                    <ClearRounded htmlColor="currentColor" style={{ height: 14, width: 14 }} />
                                                </button>
                                            )}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className={classes.separator} />
                        <button className={classes.confirmBtn} onClick={onConfirmClick} >Confirm</button>
                    </>
                ) : null
            }
        </div>
    )
}

const ShareLinkCard = ({ message, links, className = "", linkClassName="" }: { message: string, links: ConnectorCardLink[], className?: string, linkClassName?: string }) => {
    const classes = useStyles()

    const firstLink = links[0]
    const hrefPrefix: Record<string, string> = {
        regular: "",
        mailTo: "mailto:",
        phone: "tel:+"
    }

    const restLinks = links.slice(1)

    const onGoTolink = (link: typeof links[number]) => {
        const url = `${hrefPrefix[link.link_type]}${link.link_type === "regular" ? link.link_url : link.destination}`
        window.open(url, link.open_link)
    }

    return (
        <>
            <div className={className} >
                <div className={`${classes.card} border-br-0 w-full`}>
                    <p>{message}</p>
                </div>
                <div onClick={() => onGoTolink(firstLink)} className={`${classes.linkContainer} border-tr-0 w-full`}>
                    <button onClick={() => onGoTolink(firstLink)} >
                        {firstLink.connector_icon_image_link && <img src={firstLink.connector_icon_image_link} height={24} width={24} />}
                        <span>
                            {firstLink.link_name}
                        </span>
                    </button>
                </div>
            </div>
            {
                restLinks.map((link) => (
                    <div key={link.id} className={`${classes.linkContainer} ${linkClassName}`} onClick={() => onGoTolink(link)} >
                        <button  onClick={() => onGoTolink(link)}>
                            {link.connector_icon_image_link && <img src={link.connector_icon_image_link} height={24} width={24} />}
                            <span>{link.link_name}</span>
                        </button>
                    </div>
                ))
            }
        </>
    )
}

const SliderScaleCard = ({ message, min, max, onUserInputConfirm, cardId, reset }: SliderScaleCardProps) => {
    const [value, setValue] = useState(min ?? 1)
    const [hide, setHide] = useState(false)
    const classes = useStyles()

    const onConfirmClick = () => {
        setHide(true)
        onUserInputConfirm(cardId, value)
    }

    return (
        <ThemeProvider theme={sliderScaleTheme} >
            <div className={classes.card} >
                <p>{message}</p>
                {
                    (!hide || reset) ? (
                        <>
                            <div className={classes.sliderScaleContainer} >
                                <Slider
                                    value={value}
                                    onChange={(_, sValue) => setValue(sValue as number)}
                                    min={min ?? 1}
                                    max={max ?? 100}
                                    step={1}
                                    name="slider-scale"
                                    id="slider-scale"
                                    valueLabelDisplay="auto"
                                />
                                <div className={classes.sliderLabelContainer}>
                                    <div>
                                        <svg width="2" height="6" viewBox="0 0 2 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 4.24609L1 1.24609" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                        <span>{min}</span>
                                    </div>
                                    <div>
                                        <svg width="2" height="6" viewBox="0 0 2 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 4.24609L1 1.24609" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                        <span>{max}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.separator} />
                            <button className={classes.confirmBtn} onClick={onConfirmClick} >Confirm</button>
                        </>
                    ) : null
                }
            </div>
        </ThemeProvider>
    )
}

const DropdownCard = ({ message, options, cardId, onUserInputConfirm, reset }: SingleChoiceCardProps) => {
    const classes = useStyles()
    const [selectedOption, setSelectedOption] = useState<string>("")
    const [hide, setHide] = useState(false)

    const transformedOptions = options.map((option) => ({ label: option, value: option }))

    const onConfirmClick = () => {
        if(!selectedOption) {
            return;
        }
        onUserInputConfirm(cardId, selectedOption)
        setHide(true)
    }

    return (
        <div className={classes.card}>
            <p>{message}</p>
            {
                (!hide || reset) ? (
                    <>
                        <CustomSelect
                            id="dropdown"
                            placeholder="Select option"
                            selectBoxWidth={248}
                            value={selectedOption}
                            onChange={(value) => setSelectedOption(value)}
                            options={transformedOptions}
                            selectClassName={classes.dropdownSelect}
                            paperStyle={{ border: 0, borderRadius: 12, padding: 0, maxWidth: 248 }}
                            optionClassName={classes.dropdownSelectOption}
                        />
                        <div className={classes.separator} />
                        <button className={classes.confirmBtn} onClick={onConfirmClick} >Confirm</button>
                    </>
                ) : null
            }
        </div>
    )
}

const ShareUserGuideControls = ({ guide }: { guide: ShareUserGuideProps["userGuides"][number] }) => {
    const classes = useStyles()
    const [open,setOpen] = useState(false)
    const { zoomIn, zoomOut } = useControls()

    const toggleZoomOutMap = () => setOpen(!open)

    return (
        <ThemeProvider theme={shareUserGuideTheme} >
            <div className={classes.shareUserGuideControls} >
                <button onClick={() => zoomOut(0.2)} aria-label="zoom out content" >
                    <ZoomOutRounded htmlColor="currentColor" />
                </button>
                <div className="vertical-separator"  />
                <button onClick={() => zoomIn(0.2)} aria-label="zoom in content" >
                    <ZoomInRounded htmlColor="currentColor" />
                </button>
                <div className="vertical-separator" />
                <button onClick={toggleZoomOutMap} aria-label="zoom out map content" >
                    <ZoomOutMapRounded htmlColor="currentColor" />
                </button>
            </div>
            <Dialog
                open={open}
                onClose={toggleZoomOutMap}
                BackdropProps={{ style: { opacity: 0.4 } }}  
            >
                <div  className={classes.shareUserGuideDialogHeader} >
                    <h4>{guide.title}</h4>
                    <button aria-label="close qr code dialog" onClick={toggleZoomOutMap} >
                        <Close htmlColor="#334155" />
                    </button>
                </div>
                <div className={classes.shareUserGuideDialogContent} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(guide.content)}} ></div>
            </Dialog>
        </ThemeProvider>
    )
}

const ShareUserGuideCard = ({ message, userGuides, width=280, className="", headerClassName="" }: ShareUserGuideProps) => {
    const classes = useStyles()

    return (
        <>
            <div className={`${classes.card} ${className}`} >
                <p>{message}</p>
            </div>
            {
                userGuides.map((guide) => (
                    <div key={guide.id} style={{ width: width }}>
                        <div className={`${classes.shareUserGuideHeader} ${headerClassName}`} >
                            <Description htmlColor="currentColor" />
                            <p>{guide.title}</p>
                        </div>
                        <TransformWrapper wheel={{ disabled: true, wheelDisabled: true }} >
                            <div className={classes.shareUserGuideContent} >
                                <div className="share-user-inner-container">
                                    <TransformComponent>
                                        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(guide.content) }} />
                                    </TransformComponent>
                                </div>
                            </div>
                            <ShareUserGuideControls guide={guide} />
                        </TransformWrapper>
                    </div>
                ))
            }
        </>
    )
}

const ShareVideoCard = ({
    message,
    videoURL,
    videoData,
    className=""
}: ShareVideoCardProps) => {
    const classes = useStyles()
    const hasYoutubeLink = videoURL.includes("youtube") || videoURL.includes("youtu.be")

    const onGoToVideoLink = () => {
        window.open(videoURL, "_blank")
    }

    return (
        <div className={`${classes.card} ${className}`} >
            <p>{message}</p>
            <div role="button" className={classes.videoContainer}>
                <div className={classes.videoTitleContainer} onClick={onGoToVideoLink}>
                    <div>
                        <img src={hasYoutubeLink ? youtubeImage : "https://vimeo.com/favicon.ico"} width={24} height={17} />
                        <span className="underline" >
                            {hasYoutubeLink ? "YouTube" : "Vimeo"}
                        </span>
                    </div>
                    <p className="underline" >{videoData.title}</p>
                </div>
                <ReactPlayer
                    light={videoData.thumbnail_url}
                    url={videoURL}
                    controls={true}
                    width="100%"
                    height={163}
                    className="react-player-container"
                    playIcon={<div className={classes.playIconOuterContainer} >
                        <div className={classes.playIconInnerContainer}>
                            <div className={classes.playIcon} />
                        </div>
                    </div>}
                />
            </div>
        </div>
    )
}

const OpenAIResponseCard: React.FC<OpenAIResponseCardProps> = ({
    reset,
    cardId,
    message,
    buttons,
    onUserInputConfirmAi
  }) => {
    const classes = useStyles();
    const [hide, setHide] = useState(false);
  
    const onOptionClick = (option: string) => () => {
      onUserInputConfirmAi(option);
    };
  
    return (
      <div className={`${classes.card} ${classes.openAICard}`}>
        <Markdown>{message}</Markdown>
        {(!hide || reset) && buttons && buttons.length > 0 && (
          <ul className={classes.optionContainer}>
            {buttons.map((option, index) => (
                <>                
              <li
                key={index}
                className={classes.option}
                onClick={(e) => {
                  e.stopPropagation();
                  onOptionClick(String(option.Title ?? option.Title))();
                }}
              >
                {option.Title}
              </li> 
                </>
               
            ))}
          </ul>
        )}
      </div>
    );
  };
  
export const endUserChat = {
    SendMessageCard,
    SingleChoiceCard,
    MultiChoiceCard,
    RatingCard,
    ShareLinkCard,
    SliderScaleCard,
    DropdownCard,
    ShareUserGuideCard,
    ShareVideoCard,
    OpenAIResponseCard
}
