import React, { useState, useEffect } from "react";
import { useReactFlow, useNodes, useViewport, Node } from "reactflow";
import { NodeData } from "../../../../components/src/CardNode.web";

function SearchNode() {
    const [searchQuery, setSearchQuery] = useState("");
    const [matchingNodes, setMatchingNodes] = useState<Node<NodeData>[]>([]);
    const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
    const [isCtrlFPressed, setIsCtrlFPressed] = useState(false);
    const { setCenter } = useReactFlow();
    const nodes = useNodes<NodeData>();
    const { zoom } = useViewport();

    const handleSearchChange = (event: any) => {
        const query = event.target.value;
        setSearchQuery(query);
        setCurrentMatchIndex(0);

        const matches = nodes.filter((node) =>
            node.data.title.toLowerCase().includes(query.toLowerCase())
        );
        setMatchingNodes(matches);

        if (matches.length > 0) {
            const firstMatchingNode = matches[0];
            setCenter(firstMatchingNode.position.x, firstMatchingNode.position.y, { zoom });
        } else {
            setCenter(0, 0, { zoom });
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter" && matchingNodes.length > 0) {
            const nextIndex = (currentMatchIndex + 1) % matchingNodes.length;
            setCurrentMatchIndex(nextIndex);
            const nextNode = matchingNodes[nextIndex];
            setCenter(nextNode.position.x, nextNode.position.y, { zoom });
        }
    };

    useEffect(() => {
        const handleGlobalKeyPress = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === "f") {
                event.preventDefault(); 
                setIsCtrlFPressed((prev) => !prev); 
                event.stopImmediatePropagation();
            }
        };

        window.addEventListener("keydown", handleGlobalKeyPress);

        return () => {
            window.removeEventListener("keydown", handleGlobalKeyPress);
        };
    }, []);

    useEffect(() => {
        if (isCtrlFPressed) {
            const searchInput = document.getElementById("search-input");
            if (searchInput) {
                searchInput.focus(); 
            }
        }
    }, [isCtrlFPressed]);

    return (
        <div
            onKeyDown={handleKeyPress}
            tabIndex={0} 
        >
            {isCtrlFPressed && (
                <input
                    id="search-input"
                    type="text"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search nodes..."
                    autoFocus
                    style={{
                        position: "fixed",
                        top: 90,
                        right: 15,
                        zIndex: 10,
                        padding: "8px",
                        fontSize: "14px",
                        borderRadius: 98,
                        height: 44,
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #CBD5E1",
                    }}
                    onBlur={()=>setIsCtrlFPressed(false)}
                />
            )}
            {matchingNodes.length > 0 && isCtrlFPressed && (
                <div
                    style={{
                        position: "fixed",
                        top: 92,
                        right: 18,
                        zIndex: 10,
                        color: "black",
                        padding: "5px 10px",
                        borderRadius: "10px",
                    }}
                >
                    {currentMatchIndex + 1} / {matchingNodes.length}
                </div>
            )}
        </div>
    );
}

export default SearchNode;
